// @ts-nocheck
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useCookieConsentContext } from "@use-cookie-consent/react";
import {
  BrandJsonLd,
  DefaultSeo,
  LocalBusinessJsonLd,
  LogoJsonLd,
  SocialProfileJsonLd,
  WebPageJsonLd,
} from "next-seo";
import { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";

import baseUrl from "../lib/baseUrl";
import { CommonProps } from "../lib/getCommonProps";
import CookieConsentBanner from "./CookieConsentBanner";
import Footer from "./Footer";
import Header from "./Header";
import PreviewAlert from "./PreviewAlert";

type Props = {
  children: React.ReactNode;
  preview?: boolean;
} & CommonProps;

const Layout = ({ children, preview, ...commonProps }: Props) => {
  const { consent } = useCookieConsentContext();
  const [cookieBanner, setCookieBanner] = useState(false);
  const averageRating =
    commonProps.commonTestimonials.reduce((acc, curr) => acc + curr.rating, 0) /
    commonProps.commonTestimonials.length;

  useEffect(() => {
    if (!consent.thirdParty) {
      setCookieBanner(true);
    } else {
      setCookieBanner(false);
    }
  }, [consent]);

  return (
    <div className="flex flex-col justify-between h-screen subpixel-antialiased">
      <DefaultSeo
        openGraph={{
          type: "website",
          locale: "en_US",
          url: baseUrl,
          images: [{ url: baseUrl + "/logo.png", alt: "Welfare Abroad Logo" }],
          site_name: "Welfare Abroad",
        }}
        // twitter={{
        //   handle: "@handle",
        //   site: "@site",
        //   cardType: "summary_large_image",
        // }}
      />
      <LogoJsonLd logo={baseUrl + "/logo.png"} url={baseUrl} />
      <BrandJsonLd id={baseUrl} logo={baseUrl + "/logo.png"} />
      <WebPageJsonLd id={baseUrl} lastReviewed={new Date().toISOString()} />
      <SocialProfileJsonLd
        type="Organization"
        name="Welfare Abroad"
        url={baseUrl}
        sameAs={[
          "https://www.facebook.com/hairtransplantabroad/",
          "https://www.instagram.com/welfare.abroad/",
          "https://uk.linkedin.com/in/welfare-abroad-520b441b3",
          "https://www.youtube.com/channel/UCXx9z0C-q0lrLP44-MBdCrg",
        ]}
      />
      <LocalBusinessJsonLd
        type="MedicalBusiness"
        id={baseUrl}
        name="Welfare Abroad"
        description="Looking for affordable treatments in Istanbul, Turkey? Welfare Abroad offers fully customizable medical travel packages for FUE Hair Transplant, Dentistry."
        url={baseUrl}
        telephone="+447503753001"
        address={{
          streetAddress:
            "Küçükbakkalköy, Saç Ekimi Bölümü, Işıklar Cd. No: 28 D:Kat: 7",
          addressLocality: "Ataşehir",
          addressRegion: "İstanbul",
          postalCode: "34750",
          addressCountry: "TR",
        }}
        geo={{
          latitude: "40.985079",
          longitude: "29.1098",
        }}
        images={[`${baseUrl}/logo.png`]}
        openingHours={[
          {
            opens: "09:00",
            closes: "20:00",
            dayOfWeek: [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ],
          },
        ]}
        rating={{
          ratingValue: averageRating,
          ratingCount: commonProps.commonTestimonials.length,
        }}
        review={commonProps.commonTestimonials.map((testimonial) => ({
          author: testimonial.name,
          datePublished: testimonial.createdAt,
          reviewBody: testimonial.content,
          reviewRating: {
            ratingValue: testimonial.rating,
          },
        }))}
        // action={{
        //   actionName: "potentialAction",
        //   actionType: "ReviewAction",
        //   target: "https://www.example.com/review/this/business",
        // }}
      />
      <Header {...commonProps} />
      {preview && <PreviewAlert />}
      <main className="mb-auto">{children}</main>
      <div className="z-50">
        <ScrollToTop
          className="flex items-center justify-center mr-24 -mb-2"
          smooth
        />
        <TawkMessengerReact propertyId="63c8f31847425128790e7eef" widgetId="1gn4fb64o" />
      </div>
      <div className="z-40">{cookieBanner && <CookieConsentBanner />}</div>
      <Footer {...commonProps} />
    </div>
  );
};

export default Layout;
