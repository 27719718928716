import Image from "next/future/image";
import Link from "next/link";

import { CommonProps } from "../lib/getCommonProps";
import Logo from "./../public/logo.png";
import FooterColumn from "./menus/FooterColumn";
import SocialMediaIcons from "./SocialMediaIcons";

type Props = CommonProps;

const Footer = ({ commonCategories, commonPages }: Props) => {
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <Image
              className="w-auto xl:h-16"
              src={Logo}
              alt="Welfare Abroad"
              placeholder="blur"
              sizes="(max-width: 1200px) 100vw,
                      20vw"
            />
            <p className="text-base text-gray-500">
              Treat yourself by choosing Welfare Abroad as your medical travel
              companion.
            </p>
            <SocialMediaIcons
              darker
              className="justify-center mx-auto lg:justify-start"
            />
            <div
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="5419b6a8b0d04a076446a9ad"
              data-businessunit-id="5d5548ae1a361a00014c68b8"
              data-style-height="24px"
              data-style-width="100%"
              data-theme="light"
              data-min-review-count="10"
              data-style-alignment="center"
            >
              <Link
                href="https://www.trustpilot.com/review/welfareabroad.com"
                passHref
              >
                <a target="_blank" rel="noopener noreferrer">
                  Trustpilot
                </a>
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 mt-12 xl:mt-0 xl:col-span-2 xl:grid-cols-4">
            <div>
              <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                Company
              </h3>
              <ul role="list" className="mt-4 space-y-4">
                {commonPages
                  .filter((page) => !page.excludeFromMenu)
                  .map((page) => (
                    <li key={page.id}>
                      <Link href={"/" + page.seo.slug} passHref>
                        <a className="text-base text-gray-500 hover:text-gray-900">
                          {page.highlightedTitlePrefix} {page.title}
                        </a>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            {commonCategories
              .filter((category) => category.flyoutMenu)
              .map((category) => {
                if (category.treatments.length > 10) {
                  const halfway = Math.ceil(category.treatments.length / 2);
                  return [
                    <FooterColumn
                      category={{
                        ...category,
                        treatments: category.treatments.slice(0, halfway),
                      }}
                      key={category.id + "a"}
                    />,
                    <FooterColumn
                      category={{
                        ...category,
                        name: "",
                        treatments: category.treatments.slice(
                          halfway,
                          category.treatments.length
                        ),
                      }}
                      key={category.id}
                    />,
                  ];
                } else {
                  return <FooterColumn category={category} key={category.id} />;
                }
              })}
          </div>
        </div>
        <div className="pt-8 mt-12 border-t border-gray-200">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()} Welfare Abroad. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
