import Link from "next/link";

import { CommonProps } from "../../lib/getCommonProps";

type Props = { category: Partial<CommonProps["commonCategories"][0]> };

const FooterColumn = (props: Props) => {
  return (
    <div>
      {props.category.name ? (
        <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
          {props.category.name}
        </h3>
      ) : (
        <div className="mb-8" />
      )}
      <ul role="list" className="mt-4 space-y-4">
        {props.category?.treatments?.map((treatment) => (
          <li key={treatment.id}>
            <Link
              href={
                "/" +
                treatment.categories
                  .map((category) => category.seo.slug)
                  .join("/") +
                "/" +
                treatment.seo.slug
              }
              passHref
            >
              <a className="text-base text-gray-500 hover:text-gray-900">
                {treatment.title}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterColumn;
